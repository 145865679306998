var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import Transition from 'react-transition-group/Transition';
import styled from '@emotion/styled';
import Downshift from 'downshift';
import { backgroundColor, border, color, display, flex, fontSize, height, left, lineHeight, position, space, themeGet, top, width, } from 'styled-system';
import { SEARCH_BAR_Z_INDEX } from '../../constants/z-index';
import { isIOSDevice } from '../../DeviceDetector';
import { useMatchesViewport } from '../../hooks/media';
import { useLocalization } from '../../providers/LocaleProvider';
import { outline, pointerEvents, textOverflow, themeTop } from '../../theme/system-utilities';
import SearchFormContainer from '../AutocompleteInput/SearchFormContainer';
import MovableButton from '../Button/MovableButton';
import { Flex } from '../Flex';
import LazyFocusLock from '../LazyFocusLock';
import VisuallyHidden from '../VisuallyHidden';
import { useSearch } from './hooks';
import Image from './Image';
import SearchClearButton from './SearchClearButton';
import SearchIcon from './SearchIcon';
import SearchInputBorder from './SearchInputBorder';
import SearchInputButton from './SearchInputButton';
import SuggestionsBox from './SuggestionsBox';
import { getSuggestionName } from './utils/search';
import { trackSearchBack, trackSearchOpen, trackSearchSubmit } from './utils/tracking';
var TRANSITION_MS = 360;
var BACK_BTN_HIDE_LEFT = '-40px';
var SEARCH_INPUT_TARGET_HEIGHT = 44;
var SEARCH_INPUT_VISUAL_HEIGHT = 36;
var FormWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), space, display);
FormWrapper.defaultProps = {
    pt: ['sp_16', 'sp_0'],
};
var Form = styled(Flex)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), position, height);
Form.defaultProps = {
    as: 'form',
    position: 'relative',
};
var SearchInputContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:hover,\n  &:focus {\n    span:last-child {\n      background-color: ", ";\n    }\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:hover,\n  &:focus {\n    span:last-child {\n      background-color: ", ";\n    }\n  }\n"])), position, display, flex, space, height, themeGet('colors.sys.primary.background.hover'));
SearchInputContainer.defaultProps = {
    position: 'relative',
    display: 'flex',
    flex: '1 1 auto',
};
var SearchInputMobileOverlay = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), display, position, width, height, top, left);
SearchInputMobileOverlay.defaultProps = {
    position: 'absolute',
    width: 1,
    height: '100%',
    top: 0,
    left: 0,
    display: [null, 'none'],
};
var FakeInputMobile = styled(VisuallyHidden)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), position, fontSize, themeTop);
FakeInputMobile.defaultProps = __assign(__assign({}, VisuallyHidden.defaultProps), { as: 'input', position: 'fixed', fontSize: 'fs_32', themeTop: 'sp_0' });
var SearchInput = styled.input(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &::placeholder {\n    color: ", ";\n  }\n\n  &[type='search'] {\n    -webkit-appearance: none;\n\n    &:invalid {\n      box-shadow: none !important;\n    }\n\n    &::-ms-clear {\n      display: none;\n    }\n\n    &::-webkit-search-decoration,\n    &::-webkit-search-cancel-button,\n    &::-webkit-search-results-button,\n    &::-webkit-search-results-decoration {\n      -webkit-appearance: none;\n    }\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &::placeholder {\n    color: ", ";\n  }\n\n  &[type='search'] {\n    -webkit-appearance: none;\n\n    &:invalid {\n      box-shadow: none !important;\n    }\n\n    &::-ms-clear {\n      display: none;\n    }\n\n    &::-webkit-search-decoration,\n    &::-webkit-search-cancel-button,\n    &::-webkit-search-results-button,\n    &::-webkit-search-results-decoration {\n      -webkit-appearance: none;\n    }\n  }\n"])), fontSize, lineHeight, space, color, pointerEvents, backgroundColor, width, border, outline, textOverflow, themeGet('colors.sys.neutral.text.default'));
SearchInput.defaultProps = {
    backgroundColor: 'transparent',
    border: 'none',
    m: 'sp_0',
    width: 1,
    outline: 'none',
    textOverflow: 'ellipsis',
    pointerEvents: ['none', 'auto'],
    color: 'sys.neutral.text.strong',
    fontSize: ['fs_16', null, null, 'fs_18'],
    lineHeight: ['lh_24', null, null, 'fs_28'],
    pl: 'sp_16',
    pr: 'sp_0',
    py: '6px',
    'data-cnstrc-search-input': '',
};
var SearchFilterQuery = function (_a) {
    var filterQuery = _a.filterQuery;
    if (!filterQuery)
        return null;
    var params = filterQuery.split('&').reduce(function (acc, param) {
        var _a = __read(param.split('='), 2), key = _a[0], value = _a[1];
        if (key && value) {
            acc[key] = value;
        }
        return acc;
    }, {});
    return (React.createElement(React.Fragment, null, Object.keys(params).map(function (key) { return (React.createElement("input", { type: "hidden", name: key, value: params[key], key: key })); })));
};
var StyledFocusLock = styled(LazyFocusLock)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), width);
// TODO: Refactor this component and enable linter rules
/* eslint-disable complexity, max-lines, max-lines-per-function */
var Search = function (_a) {
    var _b = _a.searchDebounce, searchDebounce = _b === void 0 ? 300 : _b, _c = _a.suggestionsLimit, suggestionsLimit = _c === void 0 ? 10 : _c, _d = _a.searchThreshold, searchThreshold = _d === void 0 ? 1 : _d, _e = _a.productsLimit, productsLimit = _e === void 0 ? 6 : _e, showOnlySearchIcon = _a.showOnlySearchIcon, params = _a.params, _f = _a.withRecentSearches, withRecentSearches = _f === void 0 ? true : _f, _g = _a.withProductSuggestions, withProductSuggestions = _g === void 0 ? true : _g, shopName = _a.shopName, filterQuery = _a.filterQuery, iconVariant = _a.iconVariant, _h = _a.withAnimation, withAnimation = _h === void 0 ? false : _h, _j = _a.overlayStacking, overlayStacking = _j === void 0 ? null : _j, _k = _a.minimalIcon, minimalIcon = _k === void 0 ? false : _k, _l = _a.withInputFilled, withInputFilled = _l === void 0 ? true : _l;
    var t = useLocalization().t;
    var matchesTablet = useMatchesViewport().matches.matchesTablet;
    var isMobile = !matchesTablet;
    var _m = useSearch({
        matchesTablet: matchesTablet,
        config: {
            searchDebounce: searchDebounce,
            suggestionsLimit: suggestionsLimit,
            productsLimit: productsLimit,
            searchThreshold: searchThreshold,
        },
        params: params,
        withRecentSearches: withRecentSearches,
        withProductSuggestions: withProductSuggestions,
        shopName: shopName,
        filterQuery: filterQuery,
        overlayStacking: overlayStacking,
        withInputFilled: withInputFilled,
    }), input = _m.input, suggestions = _m.suggestions, recentSearches = _m.recentSearches;
    return (React.createElement(Transition, { in: input.flags.isLocked, timeout: TRANSITION_MS, onEnter: function () {
            // After we set the top in `lockSearch` callback remove
            // the top value from styles to start "entering" animation
            input.refs.container.current.style.top = null;
            trackSearchOpen(shopName);
        }, onEntered: function () {
            // After the animation ended, move focus to the real input
            // field (iOS keyboard is already on screen, everything is fine)
            input.refs.input.current.focus();
        }, onExited: function () {
            // After exit animation, clean inline styles
            input.refs.container.current.style.willChange = null;
            input.refs.container.current.style.top = null;
        } }, function (lockedTransitionState) { return (React.createElement(Downshift, { inputValue: input.value, itemToString: getSuggestionName, isOpen: suggestions.flags.isOpen, onSelect: suggestions.handlers.handleSelect, onStateChange: suggestions.handlers.handleStateChange, 
        // TODO: Replace with useId after migration to React 18.
        // This doesn't cause issues, as Search is rendered only once on the page.
        id: "search-bar-input" }, function (_a) {
        var getRootProps = _a.getRootProps, getInputProps = _a.getInputProps, getItemProps = _a.getItemProps, getMenuProps = _a.getMenuProps, getLabelProps = _a.getLabelProps, setHighlightedIndex = _a.setHighlightedIndex, highlightedIndex = _a.highlightedIndex, isOpen = _a.isOpen;
        var rootProps = getRootProps({
            onFocus: input.handlers.handleFocus,
            onBlur: input.handlers.handleBlur,
            // Form container animation is half the time of the whole
            // animation cycle. We sync a lot of things with the same timeout
            // allowing to show the form first, before animating buttons
            timeout: TRANSITION_MS / 2,
            transitionState: lockedTransitionState,
            ref: input.refs.container,
            'data-testid': 'search-container',
        }, 
        // Downshift doesn't recognize that the ref is passed correctly
        // https://github.com/downshift-js/downshift#getrootprops
        { suppressRefError: true });
        var formProps = {
            name: 'search',
            action: input.actionPath,
            onClick: function (event) {
                if (!isMobile) {
                    input.refs.input.current.focus();
                }
                // Prevent default click tracking on the form. This
                // will allow to push event only when it is coming from
                // submit event
                event.nativeEvent.trackingPreventDefault = true;
            },
            locked: input.flags.isLocked,
            height: SEARCH_INPUT_TARGET_HEIGHT,
            alignItems: 'center',
            onSubmit: function () { return trackSearchSubmit(input.value, shopName); },
        };
        var menuProps = getMenuProps({
            open: isOpen,
            refKey: 'containerRef',
        });
        var inputProps = getInputProps({
            name: 'query',
            type: 'sys.neutral.text.strong',
            required: true,
            placeholder: input.placeholder,
            onChange: input.handlers.handleValueChange,
            onKeyDown: input.handlers.handleKeyDown,
            ref: input.refs.input,
            'data-testid': 'search-bar-input',
            pointerEvents: input.flags.isLocked ? 'auto' : undefined,
        });
        var showSearchIconInsteadForm = !input.flags.isLocked && !!showOnlySearchIcon && lockedTransitionState === 'exited';
        var hasExtraPadding = isIOSDevice() && input.flags.isKeyboardOpen && isOpen && isMobile;
        return (React.createElement(React.Fragment, null,
            React.createElement(StyledFocusLock, { width: "100%", disabled: !isOpen },
                React.createElement(SearchFormContainer, __assign({}, rootProps),
                    React.createElement(FormWrapper
                    // Adjust paddings when locked
                    , __assign({ 
                        // Adjust paddings when locked
                        px: lockedTransitionState === 'exited' ? 0 : 3, pb: lockedTransitionState === 'exited' ? 0 : '12px', "data-testid": "form-wrapper" }, (showSearchIconInsteadForm && { display: 'none' })),
                        React.createElement(Form, __assign({}, formProps),
                            React.createElement(MovableButton, { "data-testid": "search-back-button", onClick: function () {
                                    input.handlers.handleBackButtonClick();
                                    trackSearchBack(input.value, shopName);
                                }, timeout: TRANSITION_MS, 
                                // Back button will appear with delay after SearchFormContainer
                                // finished animating, but will disappear first
                                delay: TRANSITION_MS / 2, hidden: !input.flags.isLocked, "aria-hidden": !input.flags.isLocked, tabIndex: !input.flags.isLocked ? -1 : 0, hideToLeft: BACK_BTN_HIDE_LEFT, mr: 2 },
                                React.createElement(VisuallyHidden, null, t('h24_back')),
                                React.createElement(Image, { name: "arrowLeft", dataTestId: "back-button-icon", title: t('h24_back'), size: 32 })),
                            React.createElement(VisuallyHidden, __assign({}, getLabelProps({ as: 'label' })), t('h24_search_bar_placeholder')),
                            React.createElement(SearchInputBorder, __assign({ "data-testid": "search-input-border", locked: input.flags.isLocked, focused: input.flags.focused, timeout: TRANSITION_MS / 2 }, (input.flags.isBodyOverlayVisible && {
                                zIndex: "".concat(SEARCH_BAR_Z_INDEX + 1),
                            }), { onClick: function () { return trackSearchOpen(shopName); } }),
                                React.createElement(SearchInputContainer, { height: SEARCH_INPUT_VISUAL_HEIGHT },
                                    !input.flags.isLocked && (
                                    // This overlay will only be visible on mobile
                                    // and only when the form is not "locked". We need
                                    // it to work around iOS bugs with screen auto scroll
                                    // when focusing on the inputs that are not completely
                                    // in the upper part of the page.
                                    //
                                    // This overlay will trigger focus on the FakeInputMobile
                                    // that is always on the top of the page, and this fake
                                    // input will start the lock screen process
                                    React.createElement(SearchInputMobileOverlay, { "data-testid": "search-overlay", onClick: function () {
                                            // Triggering focus immediately on click allows
                                            // for iOS keyboard to appear on the screen
                                            input.refs.fakeInput.current.focus();
                                        }, onFocus: function () {
                                            input.refs.fakeInput.current.focus();
                                        }, tabIndex: 0 })),
                                    React.createElement(SearchInput, __assign({}, inputProps)),
                                    React.createElement(SearchFilterQuery, { filterQuery: filterQuery }),
                                    React.createElement(SearchClearButton, { input: input, shopName: shopName }),
                                    React.createElement(SearchInputButton, { iconVariant: iconVariant }))))),
                    showSearchIconInsteadForm && (React.createElement(SearchIcon, { withAnimation: withAnimation, minimalIcon: minimalIcon, onClick: function () { var _a; return (_a = input.refs.fakeInput.current) === null || _a === void 0 ? void 0 : _a.focus(); } })),
                    React.createElement(Transition, { in: isOpen, timeout: TRANSITION_MS / 2 },
                        React.createElement(SuggestionsBox, { suggestions: suggestions.suggestions, recentSearches: recentSearches, productSuggestions: suggestions.productSuggestions, listItems: suggestions.listItems, open: isOpen, timeout: TRANSITION_MS / 2, inputValue: input.value, highlightedIndex: highlightedIndex, getItemProps: getItemProps, menuProps: menuProps, hasExtraPadding: hasExtraPadding, isBodyOverlayVisible: input.flags.isBodyOverlayVisible, exitSuggestions: suggestions.handlers.exit, setHighlightedIndex: setHighlightedIndex, inputContainerRef: input.refs.container, shopName: shopName })))),
            React.createElement("div", { "aria-hidden": "true", ref: input.refs.placeholder, style: {
                    display: lockedTransitionState === 'exited' ? 'none' : 'block',
                    // Search height 40px + padding top 16px, we can do some fancy calculations for
                    // this but eh...
                    height: '56px',
                } }),
            isMobile && (React.createElement("label", { htmlFor: "search-box-input-focus-helper" },
                React.createElement(FakeInputMobile, { ref: input.refs.fakeInput, onFocus: input.handlers.lockSearch, id: "search-box-input-focus-helper", "aria-label": inputProps.placeholder, "data-testid": "search-box-input-focus-helper" })))));
    })); }));
};
export default Search;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
