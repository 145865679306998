var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { alignItems, borderColor, borderRadius, borderStyle, borderWidth, color, display, flex, space, themeGet, width, } from 'styled-system';
import { Key } from '../../enums/Keyboard';
import { boxSizing, cursor, textDecoration, whiteSpace } from '../../theme/system-utilities';
import { Link } from '../Link';
import { SmallText } from '../Text';
import Image from './Image';
import SuggestionsListItemName from './SuggestionsListItemName';
import { trackSuggestionClick } from './utils/tracking';
var SuggestionsListItemContainer = styled.li(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), display, width, boxSizing);
SuggestionsListItemContainer.defaultProps = {
    display: 'block',
    width: 1,
    boxSizing: 'border-box',
};
var suggestionsListItemLinkHighlightedCSS = function (_a) {
    var theme = _a.theme;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  background-color: ", ";\n  border-color: ", ";\n"], ["\n  color: ", ";\n  background-color: ", ";\n  border-color: ", ";\n"])), themeGet('colors.sys.neutral.text.hover')({
        theme: theme,
    }), themeGet('colors.sys.neutral.background.hover')({
        theme: theme,
    }), themeGet('colors.sys.neutral.border.hover')({ theme: theme }));
};
var SuggestionsListItemLink = styled(Link)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  :hover,\n  :focus,\n  :active {\n    ", ";\n    ", ";\n  }\n\n  ", ";\n\n  :focus,\n  :active {\n    outline: 2px solid ", ";\n    border-color: transparent;\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  :hover,\n  :focus,\n  :active {\n    ", ";\n    ", ";\n  }\n\n  ", ";\n\n  :focus,\n  :active {\n    outline: 2px solid ", ";\n    border-color: transparent;\n  }\n"])), color, borderWidth, borderStyle, borderColor, borderRadius, display, alignItems, width, space, boxSizing, cursor, color, textDecoration, function (_a) {
    var highlighted = _a.highlighted;
    return highlighted && suggestionsListItemLinkHighlightedCSS;
}, themeGet('colors.sys.secondary.border.focus'));
SuggestionsListItemLink.defaultProps = {
    display: 'flex',
    alignItems: 'center',
    p: 'sp_8',
    boxSizing: 'border-box',
    cursor: 'pointer',
    width: 1,
    color: 'sys.neutral.text.default',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderRadius: [null, 'medium'],
    textDecoration: 'none',
};
var SuggestionsListItemCount = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), flex, space, whiteSpace);
SuggestionsListItemCount.defaultProps = {
    as: SmallText,
    flex: 'none',
    ml: 2,
    whiteSpace: 'nowrap',
};
var SuggestionsListItem = function (_a) {
    var suggestionsItem = _a.suggestionsItem, highlighted = _a.highlighted, inputValue = _a.inputValue, isMobile = _a.isMobile, listIndex = _a.listIndex, listItems = _a.listItems, exitSuggestions = _a.exitSuggestions, shopName = _a.shopName, props = __rest(_a, ["suggestionsItem", "highlighted", "inputValue", "isMobile", "listIndex", "listItems", "exitSuggestions", "shopName"]);
    var handleLinkClick = function (event) {
        // We want to preserve link behaviour
        // and not delegate it to Downshift,
        // that way people can click on a link
        // to open in new tabs, etc
        event.stopPropagation();
        trackSuggestionClick({
            inputValue: inputValue,
            label: suggestionsItem.name,
            position: listIndex,
            length: listItems.length,
            shopName: shopName,
        });
    };
    var handleLinkKeyDown = function (_a) {
        var key = _a.key;
        if (key === Key.Esc) {
            exitSuggestions(true);
        }
    };
    return (React.createElement(SuggestionsListItemContainer, __assign({ "data-testid": "suggestions-list-item", highlighted: highlighted, "data-cnstrc-item-section": "Search Suggestions" }, props),
        React.createElement(SuggestionsListItemLink, { highlighted: highlighted, href: suggestionsItem.urlEncoded, onKeyDown: handleLinkKeyDown, onClick: handleLinkClick, "data-testid": "suggestions-list-item-link" },
            React.createElement(Image, { src: suggestionsItem.image, alt: suggestionsItem.name, "data-testid": "suggestions-list-item-image" }),
            React.createElement(SuggestionsListItemName, { suggestionsItem: suggestionsItem, inputValue: inputValue }),
            Boolean(suggestionsItem.count) && (React.createElement(SuggestionsListItemCount, { "data-testid": "suggestions-list-item-count" }, suggestionsItem.count)))));
};
export { SuggestionsListItemContainer, SuggestionsListItemLink };
export default SuggestionsListItem;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
