var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { backgroundColor, borderRadius, justifyContent, minWidth, size, space, } from 'styled-system';
import { useLocalization } from '../../providers/LocaleProvider';
import { fill, themeBottom, themeHeight, themeMinWidth, themeTop, } from '../../theme/system-utilities';
import { Button } from '../Button';
import Icon from '../Icon';
var ICON_SIZE = 14;
var SearchIcon = styled(Icon)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), size, space, fill, themeTop, themeBottom, minWidth);
var searchIconStyles = {
    my: 'auto',
    mr: 'sp_0',
    size: ICON_SIZE,
    minWidth: ICON_SIZE,
};
var ButtonContainer = styled(Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), space, backgroundColor, borderRadius, themeHeight, themeMinWidth, justifyContent);
var buttonContainerStyles = {
    my: 'auto',
    mx: 'sp_2',
    bg: 'sys.primary.background.default',
    borderRadius: 'circle',
    themeHeight: 'sp_32',
    themeMinWidth: 'sp_32',
    justifyContent: 'center',
};
var buttonContainerSecondaryStyles = {
    kind: 'secondary',
    bg: 'sys.neutral.background.default',
    p: 'sp_8',
};
var SearchInputButton = function (_a) {
    var iconVariant = _a.iconVariant;
    var t = useLocalization().t;
    return (React.createElement(ButtonContainer, __assign({ "data-cnstrc-search-submit-btn": "", type: "submit" }, buttonContainerStyles, (iconVariant === 'secondary' && buttonContainerSecondaryStyles)),
        React.createElement(SearchIcon, __assign({ name: "searchFillSmall", ariaLabel: t('h24_search_button_text'), fill: iconVariant === 'secondary' ? 'sys.neutral.icon.default' : 'sys.neutral.icon.inverted' }, searchIconStyles))));
};
export default SearchInputButton;
var templateObject_1, templateObject_2;
