import { useEffect } from 'react';
var SEARCH_QUERY_KEY = 'query';
export var useQuery = function (setInputValue, withInputFilled) {
    useEffect(function () {
        var params = new URLSearchParams(location.search);
        var searchQuery = params.get(SEARCH_QUERY_KEY);
        if (searchQuery && withInputFilled) {
            setInputValue(searchQuery);
        }
    }, []);
};
