var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from '@emotion/styled';
import { alignItems, display, height, maxWidth, size, width } from 'styled-system';
import { fill, objectFit, themeHeight, themeWidth } from '../../theme/system-utilities';
import { Flex } from '../Flex';
import Icon from '../Icon';
var ImgIconContainer = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), themeWidth, themeHeight, alignItems);
ImgIconContainer.defaultProps = {
    themeWidth: 'sp_32',
    themeHeight: 'sp_32',
    alignItems: 'center',
    justifyContent: 'center',
};
var Image = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), width, maxWidth, height, objectFit);
Image.defaultProps = {
    width: 1,
    maxWidth: '100%',
    height: '100%',
    objectFit: 'contain',
};
var StyledIcon = styled(Icon)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), display, size, objectFit, fill);
StyledIcon.defaultProps = {
    display: 'inline-block',
    objectFit: 'contain',
    fill: 'sys.neutral.icon.default',
};
var Img = function (_a) {
    var src = _a.src, alt = _a.alt, name = _a.name, title = _a.title, dataTestId = _a.dataTestId, className = _a.className, sizeProp = _a.size, props = __rest(_a, ["src", "alt", "name", "title", "dataTestId", "className", "size"]);
    return (React.createElement(ImgIconContainer, __assign({}, props), src ? (React.createElement(Image, { src: src, alt: alt })) : (React.createElement(StyledIcon, { name: name, title: title, dataTestId: dataTestId, size: sizeProp, className: className }))));
};
export default Img;
var templateObject_1, templateObject_2, templateObject_3;
