var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { SEARCH_BAR_Z_INDEX } from '../../../constants/z-index';
var IOS_SOFT_KEYBOARD_HEIGHT = 346;
var IOS_SOFT_KEYBOARD_QUICK_TYPE_HEIGHT = 20;
var getWidthStyle = function (isSingleColumn) {
    return isSingleColumn
        ? { minWidth: [null, '320px', null, null] }
        : { minWidth: [null, '320px', '817px', '995px'] };
};
var getListStyles = function (_a) {
    var hasExtraPadding = _a.hasExtraPadding, timeout = _a.timeout, isBodyOverlayVisible = _a.isBodyOverlayVisible, open = _a.open, isSingleColumn = _a.isSingleColumn;
    return (__assign(__assign(__assign({}, getWidthStyle(isSingleColumn)), { borderTopStyle: ['solid', 'none'], borderTopWidth: ['small', 'none'], borderTopColor: ['sys.neutral.border.weak', 'none'], p: ['sp_8', 'sp_16'], display: open ? 'flex' : 'none', opacity: open ? 1 : 0, transitionTimingFunction: open ? 'ease-out' : 'ease-in', pb: hasExtraPadding ? IOS_SOFT_KEYBOARD_HEIGHT + IOS_SOFT_KEYBOARD_QUICK_TYPE_HEIGHT : 'sp_8', transition: "".concat(timeout, "ms") }), (isBodyOverlayVisible && { zIndex: SEARCH_BAR_Z_INDEX })));
};
export default getListStyles;
