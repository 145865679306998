var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { backgroundColor, borderRadius, position, size, space, width, } from 'styled-system';
import { useLocalization } from '../../../common/providers/LocaleProvider';
import { fill, themeBottom, themeLeft, themeRight, themeTop, } from '../../theme/system-utilities';
import { Flex } from '../Flex';
import Icon from '../Icon';
import { Text } from '../Text';
var ICON_SIZE = 32;
var FallBackText = styled(Text)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
FallBackText.defaultProps = __assign(__assign({}, Text.defaultProps), { lineHeight: 'lh_24', fontSize: 'fs_16', color: 'sys.neutral.text.default', textAlign: 'center' });
var DefaultImageBackground = styled(Flex)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", "\n  ", "\n  ", ";\n"], ["\n  ", ";\n  ", "\n  ", "\n  ", ";\n"])), backgroundColor, borderRadius, position, size);
DefaultImageBackground.defaultProps = {
    bg: 'sys.neutral.background.weak',
    borderRadius: 'medium',
    position: 'relative',
    pt: '100%',
    width: 1,
};
var DefaultImage = styled(Icon)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), position, width, fill, size);
DefaultImage.defaultProps = {
    role: 'img',
    size: ICON_SIZE,
    fill: 'sys.neutral.icon.default',
};
var ContentContainer = styled(Flex)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), position, space, themeTop, themeBottom, themeRight, themeLeft, width);
ContentContainer.defaultProps = {
    position: 'absolute',
    themeTop: 'sp_0',
    themeBottom: 'sp_0',
    themeLeft: 'sp_0',
    themeRight: 'sp_0',
    margin: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
};
var DefaultSquareProductImage = function (_a) {
    var title = _a.title, href = _a.href;
    var t = useLocalization().t;
    return (React.createElement(DefaultImageBackground, __assign({ "data-testid": "default-square-product-image", title: title, "aria-label": title }, (href && { as: 'a', href: href })),
        React.createElement(ContentContainer, null,
            React.createElement(DefaultImage, { name: "defaultProductImage", ariaLabel: title }),
            React.createElement(FallBackText, null, t('h24_no_picture_available')))));
};
export default DefaultSquareProductImage;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
