var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from '@emotion/styled';
import { color, flex, overflow, space } from 'styled-system';
import { useLocalization } from '../../providers/LocaleProvider';
import { fill, textOverflow, whiteSpace } from '../../theme/system-utilities';
import { Button } from '../Button';
import Icon from '../Reclamation/Icon';
import { Text } from '../Text';
import Image from './Image';
import { SuggestionsListItemContainer, SuggestionsListItemLink } from './SuggestionsListItem';
import { trackRemoveRecentSearch, trackSuggestionClick } from './utils/tracking';
var NameContainer = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), flex, color, whiteSpace, overflow, textOverflow, space);
NameContainer.defaultProps = {
    as: Text,
    flex: '1 1 auto',
    color: 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: ['fs_14', 'fs_16'],
    py: 'sp_2',
    ml: 'sp_8',
};
var DeleteButton = styled(Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), color);
DeleteButton.defaultProps = {
    kind: 'bare',
    color: 'sys.neutral.icon.default',
};
var DeleteIcon = styled(Icon)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), fill);
DeleteIcon.defaultProps = {
    size: 20,
    fill: 'sys.neutral.icon.default',
};
var RecentSearchesListItem = function (_a) {
    var suggestionsItem = _a.suggestionsItem, highlighted = _a.highlighted, listIndex = _a.listIndex, listItems = _a.listItems, isEditable = _a.isEditable, onRemove = _a.onRemove, props = __rest(_a, ["suggestionsItem", "highlighted", "listIndex", "listItems", "isEditable", "onRemove"]);
    var prefix = useLocalization().prefix;
    var trackingParams = {
        label: suggestionsItem.name,
        position: listIndex,
        length: listItems.length,
        isRecentSearch: true,
    };
    var handleLinkClick = function (event) {
        // Preserve link behaviour e.g. to open in new tab
        event.stopPropagation();
        trackSuggestionClick(trackingParams);
    };
    var handleDelete = function (event) {
        event.preventDefault();
        event.stopPropagation();
        onRemove();
        trackRemoveRecentSearch(trackingParams);
    };
    return (React.createElement(SuggestionsListItemContainer, __assign({ "data-testid": "recent-search-item" }, props),
        React.createElement(SuggestionsListItemLink, { highlighted: highlighted, href: prefix(suggestionsItem.urlEncoded), onClick: handleLinkClick },
            React.createElement(Image, { src: suggestionsItem.image, alt: suggestionsItem.name }),
            React.createElement(NameContainer, null, suggestionsItem.name),
            isEditable && (React.createElement(DeleteButton, { onClick: handleDelete },
                React.createElement(DeleteIcon, { name: "clear", dataTestId: "clear-icon" }))))));
};
export default RecentSearchesListItem;
var templateObject_1, templateObject_2, templateObject_3;
