var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { color, flex, flexDirection, fontSize, lineHeight, maxWidth, space, width, } from 'styled-system';
import { useLocalization } from '../../providers/LocaleProvider';
import { listStyle, textDecoration } from '../../theme/system-utilities';
import { Button } from '../Button';
import { Flex } from '../Flex';
import { linkStyles } from '../Link';
import { BodyBoldBB1 } from '../Typography';
import RecentSearchesListItem from './RecentSearchesListItem';
import { mapRecentSearchToSuggestionItem } from './utils/search';
import { trackEditRecentSearches, trackRemoveAllRecentSearches } from './utils/tracking';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), space, width, maxWidth);
Container.defaultProps = {
    p: [null, null, 'sp_24'],
    width: 1,
    maxWidth: [null, null, null, '100%'],
};
var ListContainer = styled.ul(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), listStyle, flex, flexDirection, width, space);
ListContainer.defaultProps = {
    listStyle: 'none',
    flex: '1',
    flexDirection: 'column',
    width: 1,
    p: 'sp_0',
    m: 'sp_0',
};
var EditButton = styled(Button)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), color, fontSize, lineHeight, textDecoration, linkStyles);
EditButton.defaultProps = {
    kind: 'bare',
    color: 'sys.neutral.text.strong',
    fontSize: ['fs_14', null, null, 'fs_16'],
    lineHeight: ['lh_20', null, null, 'lh_24'],
    textDecoration: 'underline',
    styled: true,
};
var TitleContainer = styled(Flex)(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
TitleContainer.defaultProps = __assign(__assign({}, Flex.defaultProps), { justifyContent: 'space-between', px: 'sp_8', py: 'sp_12' });
var RecentSearchesList = function (_a) {
    var recentSearches = _a.recentSearches, highlightedIndex = _a.highlightedIndex, getItemProps = _a.getItemProps, listItems = _a.listItems;
    var t = useLocalization().t;
    var _b = __read(useState(false), 2), isEditable = _b[0], setIsEditable = _b[1];
    var suggestionItems = recentSearches.list.map(mapRecentSearchToSuggestionItem);
    var editLabel = t('h24_search_edit_recent_searches');
    var editClickHandler = function () {
        setIsEditable(true);
        trackEditRecentSearches(editLabel);
    };
    var deleteAllLabel = t('h24_search_delete_all');
    var deleteAllClickHandler = function () {
        recentSearches.handlers.removeAll();
        trackRemoveAllRecentSearches({
            label: deleteAllLabel,
            items: suggestionItems.map(function (_a) {
                var name = _a.name;
                return name;
            }),
            length: suggestionItems.length,
        });
    };
    return (React.createElement(Container, { "data-testid": "recent-searches" },
        React.createElement(TitleContainer, null,
            React.createElement(BodyBoldBB1, { color: "sys.neutral.text.strong" }, t('h24_search_recent_searches')),
            isEditable ? (React.createElement(EditButton, { onClick: deleteAllClickHandler }, deleteAllLabel)) : (React.createElement(EditButton, { onClick: editClickHandler }, editLabel))),
        React.createElement(ListContainer, null, suggestionItems.slice(0, 10).map(function (item, index) { return (React.createElement(RecentSearchesListItem, __assign({ key: item.name }, getItemProps({
            item: item,
            // Downshift "swallows" item from the props,
            // we have to pass it with different key
            suggestionsItem: item,
            highlighted: highlightedIndex === index,
            listIndex: index,
            listItems: listItems,
            isEditable: isEditable,
            onRemove: function () { return recentSearches.handlers.remove(index); },
        })))); }))));
};
export default RecentSearchesList;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
