var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { color, flex, overflow, space } from 'styled-system';
import { textOverflow, whiteSpace } from '../../theme/system-utilities';
import { createHighlightedText } from './utils/search';
var Container = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), flex, color, whiteSpace, overflow, textOverflow, space);
var containerStyles = {
    flex: '1 1 auto',
    color: 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: ['fs_14', 'fs_16'],
    py: 'sp_2',
    ml: 'sp_8',
};
var useHighlightedText = function (_a, inputValue) {
    var name = _a.name;
    return useMemo(function () { return createHighlightedText(name, inputValue); }, [name, inputValue]);
};
var SuggestionsListItemName = function (_a) {
    var suggestionsItem = _a.suggestionsItem, inputValue = _a.inputValue;
    var highlightedText = useHighlightedText(suggestionsItem, inputValue);
    return (React.createElement(Container, __assign({ dangerouslySetInnerHTML: { __html: highlightedText }, "data-testid": "suggestions-list-item-name" }, containerStyles)));
};
export default SuggestionsListItemName;
var templateObject_1;
