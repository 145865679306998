var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from '@emotion/styled';
import { borderRadius, color, position, size, space, width } from 'styled-system';
import { useIsImageLoaded } from '../../hooks/image';
import { themeTop } from '../../theme/system-utilities';
import DefaultSquareProductImage from '../DefaultProductImage/DefaultSquareProductImage';
import ResponsiveImage from '../ResponsiveImage';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), position, space, width);
Container.defaultProps = {
    position: 'relative',
    pt: '100%',
    width: 1,
};
var ProductImage = function (_a) {
    var alt = _a.alt, _b = _a.imgIndex, imgIndex = _b === void 0 ? 0 : _b, isReplaced = _a.isReplaced, src = _a.src, widths = _a.widths, fallbackSize = _a.fallbackSize, visibleProductsCount = _a.visibleProductsCount;
    var isBelowTheFold = imgIndex >= visibleProductsCount;
    var _c = useIsImageLoaded(isBelowTheFold), ref = _c.ref, isLoaded = _c.isLoaded;
    return (React.createElement(Container, { ref: ref }, isLoaded && (React.createElement(StyledImage, { alt: alt, src: src, widths: widths, fallbackSize: fallbackSize, isLazy: isBelowTheFold && !isReplaced, highPriority: !isBelowTheFold && imgIndex < 3 }))));
};
var StyledImage = styled(ResponsiveImage)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), color, borderRadius, position, themeTop, size);
StyledImage.defaultProps = {
    borderRadius: 'medium',
    size: '100%',
    position: 'absolute',
    themeTop: 'sp_0',
};
var ResponsiveProductImage = function (_a) {
    var _b;
    var images = _a.images, alt = _a.alt, props = __rest(_a, ["images", "alt"]);
    var src = (_b = images[0]) === null || _b === void 0 ? void 0 : _b.path;
    if (!src) {
        return React.createElement(DefaultSquareProductImage, { title: alt });
    }
    return React.createElement(ProductImage, __assign({ alt: alt, src: src }, props));
};
export default ResponsiveProductImage;
var templateObject_1, templateObject_2;
