var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from '@emotion/styled';
import { flex, flexDirection, space, width } from 'styled-system';
import { useMatchesViewport } from '../../hooks/media';
import { useLocalization } from '../../providers/LocaleProvider';
import { listStyle } from '../../theme/system-utilities';
import { BodyBoldBB1 } from '../Typography';
import SuggestionsListItem from './SuggestionsListItem';
var ListContainer = styled.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), listStyle, flex, flexDirection, width, space);
var listContainerStyles = {
    listStyle: 'none',
    flex: '1',
    flexDirection: 'column',
    width: 1,
    p: 'sp_0',
    m: 'sp_0',
};
var SuggestionsListContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), space, width);
var suggestionsListContainerStyles = {
    p: [null, null, 'sp_24'],
    width: 1,
};
var titleStyles = {
    color: 'sys.neutral.text.strong',
    px: 'sp_8',
    py: 'sp_12',
};
var SuggestionsList = function (_a) {
    var suggestions = _a.suggestions, highlightedIndex = _a.highlightedIndex, getItemProps = _a.getItemProps, props = __rest(_a, ["suggestions", "highlightedIndex", "getItemProps"]);
    var _b = useLocalization(), t = _b.t, prefix = _b.prefix;
    var matchesTablet = useMatchesViewport().matches.matchesTablet;
    var isMobile = !matchesTablet;
    return (React.createElement(SuggestionsListContainer, __assign({ "data-testid": "suggestions-list-container" }, suggestionsListContainerStyles),
        React.createElement(BodyBoldBB1, __assign({}, titleStyles), t('h24_search_suggestion_title')),
        React.createElement(ListContainer, __assign({}, listContainerStyles), suggestions.map(function (item, index) {
            item = __assign(__assign({}, item), { urlEncoded: prefix(item.urlEncoded) });
            return (React.createElement(SuggestionsListItem, __assign({ key: item.name }, getItemProps(__assign(__assign({}, props), { item: item, index: index, 
                // Downshift "swallows" item from the props,
                // we have to pass it with different key
                suggestionsItem: item, highlighted: highlightedIndex === index, isMobile: isMobile, listIndex: index })), { "data-cnstrc-item-name": item.name })));
        }))));
};
export default SuggestionsList;
var templateObject_1, templateObject_2;
