var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { ProductEventDataList } from 'corgi-types';
import { display, flexWrap, gridGap, gridTemplateColumns, justifyContent, space, width, } from 'styled-system';
import { useMatchesViewport } from '../../hooks/media';
import { useLocalization } from '../../providers/LocaleProvider';
import { useObserveArticles } from '../../tracking/useObserveArticles';
import { BodyBoldBB1 } from '../Typography';
import SuggestionProductCard from './SuggestionProductCard';
var PRODUCTS_COUNT = 4;
var PRODUCTS_COUNT_XL = 6;
var DEBOUNCE_TRACKING_VIEW = 1000;
var itemWidth = ['calc((100% - 4px) / 2)', null, null, 'calc((100% - 8px) / 3)'];
var titleProps = {
    color: 'sys.neutral.text.strong',
    px: 'sp_8',
    py: 'sp_12',
    mt: ['sp_8', null, 'sp_0'],
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), space, width);
Container.defaultProps = {
    px: [null, null, 'sp_12', 'sp_4'],
    m: [null, null, 'sp_24'],
    mb: ['sp_16', 'sp_20', 'sp_24', 'sp_32'],
    width: '100%',
};
var ListContainer = styled.ul(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), display, flexWrap, gridGap, gridTemplateColumns, justifyContent, space);
ListContainer.defaultProps = {
    display: 'flex',
    flexWrap: 'wrap',
    gridGap: 'sp_4',
    gridTemplateColumns: ['repeat(2, minmax(0, 1fr))', null, 'repeat(3, minmax(0, 1fr))'],
    m: 'sp_0',
    p: 'sp_0',
};
var ListViewItem = styled.li(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), display, width, space);
ListViewItem.defaultProps = {
    display: 'inline-block',
    width: itemWidth,
    mb: 'sp_4',
};
var transformToTrackingArticle = function (productSuggestions) {
    return productSuggestions.map(function (_a) {
        var _b, _c, _d;
        var sku = _a.sku, prices = _a.prices, name = _a.name;
        return ({
            sku: sku,
            tracking: {
                productSimpleSKU: sku,
                productName: name,
                productPrice: (_c = (_b = prices.special) === null || _b === void 0 ? void 0 : _b.value) !== null && _c !== void 0 ? _c : (_d = prices.regular) === null || _d === void 0 ? void 0 : _d.value,
            },
        });
    });
};
var SuggestionsProductList = function (_a) {
    var productSuggestions = _a.productSuggestions, onMouseEnter = _a.onMouseEnter;
    var matchesLargeDesktop = useMatchesViewport(false).matches.matchesLargeDesktop;
    var t = useLocalization().t;
    useObserveArticles({
        articles: transformToTrackingArticle(productSuggestions),
        listType: ProductEventDataList.SearchAutoSuggest,
        dataTestId: 'suggestions-product-item',
        debounce: DEBOUNCE_TRACKING_VIEW,
    });
    return (React.createElement(Container, { "data-testid": "suggestions-product-list-container" },
        React.createElement(BodyBoldBB1, __assign({}, titleProps), t('h24_search_suggestion_product_title')),
        React.createElement(ListContainer, null, productSuggestions
            .slice(0, matchesLargeDesktop ? PRODUCTS_COUNT_XL : PRODUCTS_COUNT)
            .map(function (item, index) { return (React.createElement(ListViewItem, { key: item.sku, onMouseEnter: onMouseEnter, role: "option" },
            React.createElement(SuggestionProductCard, __assign({}, item, { index: index })))); }))));
};
export default SuggestionsProductList;
var templateObject_1, templateObject_2, templateObject_3;
