var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from '@emotion/styled';
import { useLocalization } from '../../providers/LocaleProvider';
import { fill } from '../../theme/system-utilities';
import MovableButton from '../Button/MovableButton';
import VisuallyHidden from '../VisuallyHidden';
import Image from './Image';
import { trackSearchClear } from './utils/tracking';
var ICON_SIZE = 24;
var CLEAR_BTN_HIDE_RIGHT = '-40px';
var StyledImage = styled(Image)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), fill);
StyledImage.defaultProps = {
    fill: 'sys.neutral.icon.default',
    size: ICON_SIZE,
};
var SearchClearButton = function (_a) {
    var input = _a.input, shopName = _a.shopName;
    var t = useLocalization().t;
    var clickHandler = function () {
        input.handlers.handleSearchClearClick();
        trackSearchClear(input.value, shopName);
    };
    return (React.createElement(MovableButton, { "data-testid": "search-clear-button", hidden: input.flags.isClearButtonHidden, "aria-hidden": input.flags.isClearButtonHidden, tabIndex: input.flags.isClearButtonHidden ? -1 : 0, hideToRight: CLEAR_BTN_HIDE_RIGHT, onClick: clickHandler },
        React.createElement(VisuallyHidden, null, t('h24_clear_search')),
        React.createElement(StyledImage, { dataTestId: "search-clear-icon", title: t('h24_clear_search'), name: "close" })));
};
export default SearchClearButton;
var templateObject_1;
