var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useEffect, useMemo, useState } from 'react';
import { getRecentSearches, saveRecentSearches } from '../../../utils/recent-searches';
import { getSelectHandler } from './suggestions/handlers';
var useGetInitialSearches = function (isEnabled) {
    return useMemo(function () { return (isEnabled ? getRecentSearches() : []); }, [isEnabled]);
};
var useHandlers = function (isEnabled) {
    var initialSearches = useGetInitialSearches(isEnabled);
    var _a = __read(useState(initialSearches), 2), recentSearches = _a[0], setRecentSearches = _a[1];
    useEffect(function () {
        if (initialSearches !== recentSearches) {
            saveRecentSearches(recentSearches);
        }
    }, [recentSearches, initialSearches]);
    var removeAll = function () { return setRecentSearches([]); };
    var remove = function (index) {
        return setRecentSearches(__spreadArray(__spreadArray([], __read(recentSearches.slice(0, index)), false), __read(recentSearches.slice(index + 1)), false));
    };
    var listItems = recentSearches.slice(0, 10).map(function (_a) {
        var keyword = _a.keyword;
        return keyword;
    });
    var handleSelect = getSelectHandler({ listItems: listItems, isRecentSearch: true });
    return {
        recentSearches: recentSearches,
        listItems: listItems,
        handlers: { removeAll: removeAll, remove: remove, handleSelect: handleSelect },
    };
};
var shouldOpen = function (_a) {
    var isSuggestionsOpen = _a.isSuggestionsOpen, isInputLocked = _a.isInputLocked, isInputFocused = _a.isInputFocused, isContainerHidden = _a.isContainerHidden, inputValue = _a.inputValue, isMobile = _a.isMobile;
    var isOpeningMobile = isMobile && isInputLocked;
    var isOpeningDesktop = !isMobile && !isContainerHidden;
    return (!isSuggestionsOpen &&
        isInputFocused &&
        inputValue.length === 0 &&
        (isOpeningMobile || isOpeningDesktop));
};
var shouldClose = function (_a) {
    var isSuggestionsOpen = _a.isSuggestionsOpen, isMobile = _a.isMobile, isInputLocked = _a.isInputLocked, isInputFocused = _a.isInputFocused, isContainerHidden = _a.isContainerHidden, inputValue = _a.inputValue;
    var isClosingMobile = isMobile && !isInputLocked && !isInputFocused;
    var isClosingDesktop = !isMobile && isContainerHidden;
    return isSuggestionsOpen || inputValue.length > 0 || isClosingDesktop || isClosingMobile;
};
export var useRecentSearches = function (_a) {
    var inputRef = _a.inputRef, isEnabled = _a.isEnabled, options = __rest(_a, ["inputRef", "isEnabled"]);
    var _b = __read(useState(false), 2), isOpen = _b[0], setIsOpen = _b[1];
    var _c = useHandlers(isEnabled), recentSearches = _c.recentSearches, listItems = _c.listItems, handlers = _c.handlers;
    useEffect(function () {
        if (recentSearches.length === 0) {
            return;
        }
        if (!isOpen && shouldOpen(options)) {
            setIsOpen(true);
        }
        if (isOpen && shouldClose(options)) {
            setIsOpen(false);
        }
    });
    useEffect(function () {
        if (recentSearches.length === 0 && isOpen) {
            setIsOpen(false);
            inputRef.current.focus();
        }
    }, [recentSearches]);
    if (!isEnabled) {
        return null;
    }
    return {
        list: recentSearches,
        listItems: listItems,
        flags: { isOpen: isOpen },
        handlers: handlers,
    };
};
