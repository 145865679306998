var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useCallback } from 'react';
import Downshift from 'downshift';
import { trackSuggestionClick } from '../../utils/tracking';
export var getSelectHandler = function (_a) {
    var inputValue = _a.inputValue, listItems = _a.listItems, isRecentSearch = _a.isRecentSearch;
    return function (selectedItem) {
        if (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.urlEncoded) {
            trackSuggestionClick({
                label: selectedItem.name,
                inputValue: inputValue,
                position: listItems.indexOf(selectedItem.name),
                length: listItems.length,
                isRecentSearch: isRecentSearch,
            });
            window.location.href = selectedItem.urlEncoded;
        }
    };
};
var getStateChangeHandler = function (unlockSearchAndClearInput) { return function (changes) {
    if (changes.type === Downshift.stateChangeTypes.keyDownEscape) {
        unlockSearchAndClearInput();
    }
}; };
var useExitHandler = function (_a) {
    var clearSuggestions = _a.clearSuggestions, setIsBodyOverlayVisible = _a.setIsBodyOverlayVisible, unlockSearchAndClearInput = _a.unlockSearchAndClearInput;
    return useCallback(function (shouldClearInput) {
        if (shouldClearInput === void 0) { shouldClearInput = false; }
        if (shouldClearInput) {
            unlockSearchAndClearInput();
        }
        clearSuggestions();
        setIsBodyOverlayVisible(false);
    }, [unlockSearchAndClearInput, clearSuggestions, setIsBodyOverlayVisible]);
};
export var useSuggestionsHandlers = function (_a) {
    var isMobile = _a.isMobile, clear = _a.clear, unlockSearchAndClearInput = _a.unlockSearchAndClearInput, setIsBodyOverlayVisible = _a.setIsBodyOverlayVisible, suggestions = _a.suggestions, options = __rest(_a, ["isMobile", "clear", "unlockSearchAndClearInput", "setIsBodyOverlayVisible", "suggestions"]);
    var exit = useExitHandler({
        clearSuggestions: clear,
        setIsBodyOverlayVisible: setIsBodyOverlayVisible,
        unlockSearchAndClearInput: unlockSearchAndClearInput,
    });
    var handleSelect = useCallback(getSelectHandler(options), [options]);
    var handleStateChange = useCallback(getStateChangeHandler(unlockSearchAndClearInput), [unlockSearchAndClearInput]);
    return {
        clear: clear,
        exit: exit,
        handleSelect: handleSelect,
        handleStateChange: handleStateChange,
    };
};
