import { useCallback } from 'react';
import { Key } from '../../../../enums/Keyboard';
var getInputValueChangeHandler = function (setInputValue, setIsEditedByUser) {
    return function (_a) {
        var value = _a.target.value;
        setInputValue(value);
        setIsEditedByUser(true);
    };
};
var getInputKeyDownHandler = function (unlockSearchAndClearInput) {
    return function (_a) {
        var key = _a.key;
        if (key === Key.Esc) {
            unlockSearchAndClearInput();
        }
    };
};
var getSearchClearClickHandler = function (ref, setInputValue) { return function () {
    var _a;
    setInputValue('');
    (_a = ref.current) === null || _a === void 0 ? void 0 : _a.focus();
}; };
export var getBackButtonClickHandler = function (_a) {
    var clearSuggestions = _a.clearSuggestions, unlockSearchAndClearInput = _a.unlockSearchAndClearInput;
    return function () {
        unlockSearchAndClearInput();
        clearSuggestions();
    };
};
export var useHandlers = function (_a) {
    var ref = _a.ref, placeholder = _a.placeholder, setValue = _a.setValue, setIsEditedByUser = _a.setIsEditedByUser, setFocused = _a.setFocused, setSearchClearFocused = _a.setSearchClearFocused, unlockSearch = _a.unlockSearch;
    var handleValueChange = useCallback(getInputValueChangeHandler(setValue, setIsEditedByUser), [
        setValue,
        setIsEditedByUser,
    ]);
    var handleFocus = function () {
        setFocused(true);
    };
    var handleBlur = function () {
        setFocused(false);
    };
    var clearValue = function () {
        setFocused(false);
        setValue('');
        ref.current.setAttribute('placeholder', placeholder);
    };
    var unlockSearchAndClearInput = useCallback(function () {
        unlockSearch();
        clearValue();
    }, [unlockSearch, setValue]);
    var handleKeyDown = useCallback(getInputKeyDownHandler(unlockSearchAndClearInput), [
        unlockSearchAndClearInput,
    ]);
    var handleSearchClearClick = useCallback(getSearchClearClickHandler(ref, setValue), [
        ref,
        setValue,
    ]);
    var handleSearchClearFocus = function () {
        setSearchClearFocused(true);
    };
    var handleSearchClearBlur = function () {
        setSearchClearFocused(false);
    };
    return {
        handleValueChange: handleValueChange,
        handleFocus: handleFocus,
        handleBlur: handleBlur,
        handleKeyDown: handleKeyDown,
        handleSearchClearClick: handleSearchClearClick,
        handleSearchClearFocus: handleSearchClearFocus,
        handleSearchClearBlur: handleSearchClearBlur,
        clearValue: clearValue,
        unlockSearchAndClearInput: unlockSearchAndClearInput,
    };
};
