var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState } from 'react';
import { OVERLAY_Z_INDEX } from '../constants/z-index';
var getBodyBeforeOverlayCss = function (overlayZIndex) {
    return "content: ' '; z-index: ".concat(overlayZIndex ? overlayZIndex : OVERLAY_Z_INDEX, "; display: block; position: absolute; height: 100%; top: 0; left: 0; right: 0; background: rgba(0, 0, 0, 0.6);");
};
var setBodyOverlay = function (styleSheet, overlayZIndex) {
    document.body.style.setProperty('position', 'sticky');
    styleSheet.insertRule("body::before { ".concat(getBodyBeforeOverlayCss(overlayZIndex), " }"), 0);
};
var getCSSRuleEntries = function (styleSheet) {
    return Array.from(styleSheet.cssRules).entries();
};
var cleanupBody = function (styleSheet) {
    var e_1, _a;
    document.body.style.setProperty('position', 'initial');
    try {
        for (var _b = __values(getCSSRuleEntries(styleSheet)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var _d = __read(_c.value, 2), index = _d[0], cssRule = _d[1];
            if (cssRule.selectorText === 'body::before') {
                styleSheet.deleteRule(index);
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
};
export var useBodyOverlay = function (overlayZIndex) {
    var _a = __read(useState(false), 2), isBodyOverlayVisible = _a[0], setIsBodyOverlayVisible = _a[1];
    useEffect(function () {
        var styleSheet = document.styleSheets.item(0);
        if (isBodyOverlayVisible) {
            setBodyOverlay(styleSheet, overlayZIndex);
        }
        else {
            cleanupBody(styleSheet);
        }
        return function () {
            cleanupBody(styleSheet);
        };
    }, [isBodyOverlayVisible]);
    return { isBodyOverlayVisible: isBodyOverlayVisible, setIsBodyOverlayVisible: setIsBodyOverlayVisible };
};
