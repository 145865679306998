var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useRef, useState, useCallback } from 'react';
var blurActiveElement = function () {
    var active = document.activeElement;
    if (active) {
        active.blur();
    }
};
var useLockSearch = function (_a) {
    var setLocked = _a.setLocked, searchContainerRef = _a.searchContainerRef;
    return useCallback(function () {
        // Before "locked" animation kicks in, we will adjust element top
        // position for animation purposes. As the element has "static" position
        // applied on locked = false, this will not affect it in any way before
        // "entering" phase starts
        if (searchContainerRef.current) {
            var rect = searchContainerRef.current.getBoundingClientRect();
            searchContainerRef.current.style.willChange = 'top, background-color';
            searchContainerRef.current.style.top = "".concat(rect.top, "px");
            setLocked();
        }
    }, []);
};
var useUnlockSearch = function (_a) {
    var setUnlocked = _a.setUnlocked, searchContainerRef = _a.searchContainerRef, searchPlaceholderRef = _a.searchPlaceholderRef;
    return useCallback(function () {
        // Before "unlocking" screen, we will adjust the top position
        // to the current placeholder position, so the form animates
        // back to the appropriate place
        if (searchPlaceholderRef.current && searchContainerRef.current) {
            var rect = searchPlaceholderRef.current.getBoundingClientRect();
            searchContainerRef.current.style.top = "".concat(rect.top, "px");
            setUnlocked();
            blurActiveElement();
        }
    }, []);
};
var useSearchLock = function () {
    var searchContainerRef = useRef(null);
    var searchPlaceholderRef = useRef(null);
    var _a = __read(useState(false), 2), isLocked = _a[0], setLocked = _a[1];
    var lockSearch = useLockSearch({
        setLocked: function () { return setLocked(true); },
        searchContainerRef: searchContainerRef,
    });
    var unlockSearch = useUnlockSearch({
        setUnlocked: function () { return setLocked(false); },
        searchContainerRef: searchContainerRef,
        searchPlaceholderRef: searchPlaceholderRef,
    });
    return {
        isLocked: isLocked,
        searchContainerRef: searchContainerRef,
        searchPlaceholderRef: searchPlaceholderRef,
        lockSearch: lockSearch,
        unlockSearch: unlockSearch,
    };
};
export default useSearchLock;
