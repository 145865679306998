var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { size, minHeight, minWidth } from 'styled-system';
import IconBase from '../Icon';
var iconResponsiveSizes = ['24px', null, null, '28px'];
var Icon = styled(IconBase)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), size, minHeight, minWidth);
Icon.defaultProps = {
    size: iconResponsiveSizes,
    minHeight: iconResponsiveSizes,
    minWidth: iconResponsiveSizes,
};
export default Icon;
var templateObject_1;
