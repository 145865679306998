var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { ProductEventDataList } from 'corgi-types';
import { size } from 'lodash';
import { backgroundColor, display, flexDirection, height, position, space, themeGet, } from 'styled-system';
import { textWithEllipsis } from '../../theme/helper';
import { cursor, textDecoration, themeRight, themeTop } from '../../theme/system-utilities';
import ArticleSmallPrice, { RenderingStrategy } from '../Price/ArticleSmallPrice';
import ResponsiveProductImage from '../ProductCard/ResponsiveProductImage';
import { Text } from '../Text';
import NewWishlistButton from '../WishlistButton/NewWishlistButton';
import { getProductSuggestionTrackingProps } from './utils/tracking';
var IMAGE_SIZES = [301, 301, 164, 164];
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:hover,\n  :focus,\n  :active {\n    outline: ", " solid ", ";\n    border-radius: ", ";\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:hover,\n  :focus,\n  :active {\n    outline: ", " solid ", ";\n    border-radius: ", ";\n  }\n"])), backgroundColor, cursor, display, flexDirection, position, height, themeGet('borderWidths.small'), themeGet('colors.sys.neutral.border.hover'), themeGet('radii.medium'));
Container.defaultProps = {
    bg: 'sys.neutral.background.default',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
};
var LinkContainer = styled.a(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), textDecoration, position, space, height);
LinkContainer.defaultProps = {
    textDecoration: 'none',
    position: 'relative',
    p: 'sp_8',
    height: '100%',
};
var Price = styled(Text)(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
Price.defaultProps = {
    color: 'sys.neutral.text.strong',
    fontSize: 'fs_14',
    fontWeight: 'bold',
    lineHeight: 'lh_20',
    my: 'sp_8',
};
var ProductName = styled(Text)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), textWithEllipsis());
ProductName.defaultProps = {
    color: 'sys.neutral.text.strong',
    fontSize: 'fs_14',
    lineHeight: 'lh_20',
};
var StyledWishlistButton = styled(NewWishlistButton)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), position, themeRight, themeTop, size);
StyledWishlistButton.defaultProps = {
    position: 'absolute',
    themeRight: 'sp_12',
    themeTop: 'sp_12',
    p: 'sp_8',
};
var SuggestionProductCard = function (_a) {
    var _b, _c;
    var sku = _a.sku, name = _a.name, productImage = _a.productImage, url = _a.url, _d = _a.prices, regular = _d.regular, special = _d.special, rrp = _d.rrp, measurement = _d.measurement, index = _a.index, metrics = _a.metrics;
    return (React.createElement(Container, { "data-testid": "suggestions-product-item", "data-sku": sku },
        React.createElement(LinkContainer, __assign({ href: url }, getProductSuggestionTrackingProps({
            href: url,
            tracking: {
                productName: name,
                productSimpleSKU: sku,
                productPrice: (_b = special === null || special === void 0 ? void 0 : special.value) !== null && _b !== void 0 ? _b : regular === null || regular === void 0 ? void 0 : regular.value,
            },
            list: ProductEventDataList.SearchAutoSuggest,
            index: index,
        })),
            React.createElement(ResponsiveProductImage, { key: productImage, images: [{ path: productImage }], widths: IMAGE_SIZES, fallbackSize: IMAGE_SIZES[1], alt: name, isReplaced: true, visibleProductsCount: 0 }),
            regular && (React.createElement(ArticleSmallPrice, { regularPrice: regular, rrpPrice: rrp, specialPrice: special, measurement: measurement, renderingStrategy: RenderingStrategy.Horizontal })),
            React.createElement(ProductName, null, name)),
        React.createElement(StyledWishlistButton, { sku: sku, size: 16, count: metrics === null || metrics === void 0 ? void 0 : metrics.wishlist.count, index: index, tracking: {
                productSimpleSKU: sku,
                productName: name,
                productPrice: (_c = special === null || special === void 0 ? void 0 : special.value) !== null && _c !== void 0 ? _c : regular === null || regular === void 0 ? void 0 : regular.value,
                list: ProductEventDataList.SearchAutoSuggest,
            }, list: ProductEventDataList.SearchAutoSuggest })));
};
export default SuggestionProductCard;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
