import { EventCategory, ProductEvent } from '../../../enums/Tracking';
import { dispatchTrackingEventGA4 } from '../../../tracking/dispatchTrackingEventGA4';
import { getPageAppTrackerProps } from '../../../tracking/getTrackerProps';
import { getProductEventData } from '../../../tracking/productEventData';
import { Element } from '../../../types/tracking';
var event = 'search_click';
var home24ShopName = 'home24';
var Placement;
(function (Placement) {
    Placement["GlobalHeader"] = "global-header";
    Placement["ShopInShopNav"] = "shop-in-shop-navigation";
})(Placement || (Placement = {}));
var Label;
(function (Label) {
    Label["Submit"] = "search-submit";
    Label["Back"] = "search-back-button";
    Label["Clear"] = "search-clear-button";
})(Label || (Label = {}));
var SuggestionType;
(function (SuggestionType) {
    SuggestionType["Keyword"] = "Keyword";
    SuggestionType["RecentSearch"] = "Recent Searches";
})(SuggestionType || (SuggestionType = {}));
export var getProductSuggestionTrackingProps = function (_a) {
    var href = _a.href, tracking = _a.tracking, list = _a.list, index = _a.index;
    return getPageAppTrackerProps({ href: href }, {
        eventCategory: EventCategory.Product,
        event: ProductEvent.Click,
        eventData: {
            products: [getProductEventData({ position: index, tracking: tracking, list: list })],
        },
    });
};
export var trackSearchOpen = function (shopName) {
    dispatchTrackingEventGA4({
        event: event,
        element: Element.Input,
        placement: shopName ? Placement.ShopInShopNav : Placement.GlobalHeader,
        shop_name: shopName !== null && shopName !== void 0 ? shopName : home24ShopName,
    });
};
export var trackSearchSubmit = function (inputValue, shopName) {
    dispatchTrackingEventGA4({
        event: event,
        element: Element.Button,
        label: Label.Submit,
        search_term: inputValue,
        placement: shopName ? Placement.ShopInShopNav : Placement.GlobalHeader,
        shop_name: shopName !== null && shopName !== void 0 ? shopName : home24ShopName,
    });
};
export var trackSearchBack = function (inputValue, shopName) {
    dispatchTrackingEventGA4({
        event: event,
        element: Element.Button,
        label: Label.Back,
        search_term: inputValue,
        placement: shopName ? Placement.ShopInShopNav : Placement.GlobalHeader,
        shop_name: shopName !== null && shopName !== void 0 ? shopName : home24ShopName,
    });
};
export var trackSearchClear = function (inputValue, shopName) {
    dispatchTrackingEventGA4({
        event: event,
        element: Element.Button,
        label: Label.Clear,
        search_term: inputValue,
        placement: shopName ? Placement.ShopInShopNav : Placement.GlobalHeader,
        shop_name: shopName !== null && shopName !== void 0 ? shopName : home24ShopName,
    });
};
export var trackSuggestionClick = function (_a) {
    var label = _a.label, position = _a.position, length = _a.length, _b = _a.inputValue, inputValue = _b === void 0 ? '' : _b, shopName = _a.shopName, _c = _a.isRecentSearch, isRecentSearch = _c === void 0 ? false : _c;
    dispatchTrackingEventGA4({
        event: event,
        element: Element.Link,
        link_text: label,
        search_term: inputValue,
        list_position: position,
        list_length: length,
        suggestion_type: isRecentSearch ? SuggestionType.RecentSearch : SuggestionType.Keyword,
        placement: shopName ? Placement.ShopInShopNav : Placement.GlobalHeader,
        shop_name: shopName !== null && shopName !== void 0 ? shopName : home24ShopName,
    });
};
export var trackEditRecentSearches = function (label) {
    dispatchTrackingEventGA4({
        event: event,
        element: Element.Button,
        button_text: label,
        suggestion_type: SuggestionType.RecentSearch,
        placement: Placement.GlobalHeader,
        shop_name: home24ShopName,
    });
};
export var trackRemoveRecentSearch = function (_a) {
    var label = _a.label, position = _a.position, length = _a.length;
    dispatchTrackingEventGA4({
        event: event,
        element: Element.Icon,
        list_position: position,
        list_length: length,
        list_items: [label],
        suggestion_type: SuggestionType.RecentSearch,
        placement: Placement.GlobalHeader,
        shop_name: home24ShopName,
    });
};
export var trackRemoveAllRecentSearches = function (_a) {
    var label = _a.label, items = _a.items, length = _a.length;
    dispatchTrackingEventGA4({
        event: event,
        element: Element.Button,
        button_text: label,
        list_length: length,
        list_items: items,
        suggestion_type: SuggestionType.RecentSearch,
        placement: Placement.GlobalHeader,
        shop_name: home24ShopName,
    });
};
