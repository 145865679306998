var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { dispatchTrackingEventGA4 } from '../../common/tracking/dispatchTrackingEventGA4';
import { Element, NavigationParent } from '../../common/types/tracking';
var NavigationEvent;
(function (NavigationEvent) {
    NavigationEvent["GlobalHeaderClick"] = "global-header_click";
    NavigationEvent["ShopInShopNavClick"] = "shop-in-shop-navigation_click";
})(NavigationEvent || (NavigationEvent = {}));
var Placement;
(function (Placement) {
    Placement["Toolbar"] = "toolbar";
    Placement["MyAccountTooltip"] = "my-account-tooltip";
})(Placement || (Placement = {}));
export var trackLogoClick = function (parentComponent, shopName) {
    if (parentComponent === void 0) { parentComponent = NavigationParent.GlobalHeader; }
    dispatchTrackingEventGA4(__assign({ event: parentComponent === NavigationParent.ShopInShopNav
            ? NavigationEvent.ShopInShopNavClick
            : NavigationEvent.GlobalHeaderClick, element: Element.Image, label: 'brand-logo', placement: Placement.Toolbar }, (shopName && { shop_name: shopName })));
};
export var trackHamburgerClick = function (parentComponent) {
    if (parentComponent === void 0) { parentComponent = NavigationParent.GlobalHeader; }
    dispatchTrackingEventGA4({
        event: parentComponent === NavigationParent.ShopInShopNav
            ? NavigationEvent.ShopInShopNavClick
            : NavigationEvent.GlobalHeaderClick,
        element: Element.Button,
        label: 'hamburger-button',
        placement: Placement.Toolbar,
    });
};
export var trackActionItemClick = function (label) {
    dispatchTrackingEventGA4({
        event: NavigationEvent.GlobalHeaderClick,
        element: Element.Icon,
        label: label,
        placement: Placement.Toolbar,
    });
};
export var trackMyAccountTooltipClick = function (label, element) {
    if (element === void 0) { element = Element.Link; }
    dispatchTrackingEventGA4({
        event: NavigationEvent.GlobalHeaderClick,
        element: element,
        clicked_text: label,
        placement: Placement.MyAccountTooltip,
    });
};
export var trackLanguageSelectorClick = function (language) {
    // Capitalize first letter (as displayed to user)
    var clicked_text = language.charAt(0).toUpperCase() + language.slice(1);
    dispatchTrackingEventGA4({
        event: NavigationEvent.GlobalHeaderClick,
        element: Element.Link,
        clicked_text: clicked_text,
        placement: Placement.Toolbar,
    });
};
