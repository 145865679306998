var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { space, minWidth, width, position, flex, flexDirection, backgroundColor, } from 'styled-system';
import { boxSizing } from '../../theme/system-utilities';
var SearchFormContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  transition-property: top, transform, opacity, background-color;\n  transition-timing-function: ease-in-out;\n\n  ", ";\n\n  ", ";\n\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  transition-property: top, transform, opacity, background-color;\n  transition-timing-function: ease-in-out;\n\n  ", ";\n\n  ", ";\n\n  ", ";\n"])), position, flex, flexDirection, width, minWidth, space, boxSizing, backgroundColor, function (_a) {
    var transitionState = _a.transitionState;
    return transitionState !== 'exited' && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      position: fixed;\n      top: 0;\n      left: 0;\n      width: 100vw;\n      height: 100vh;\n      z-index: 9999999;\n    "], ["\n      position: fixed;\n      top: 0;\n      left: 0;\n      width: 100vw;\n      height: 100vh;\n      z-index: 9999999;\n    "])));
}, function (_a) {
    var transitionState = _a.transitionState, timeout = _a.timeout;
    return ['entering', 'entered'].includes(transitionState) && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      background-color: white;\n      transition-duration: ", "ms;\n    "], ["\n      background-color: white;\n      transition-duration: ", "ms;\n    "])), timeout);
}, function (_a) {
    var transitionState = _a.transitionState, timeout = _a.timeout;
    return transitionState !== 'entering' && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      transition-duration: ", "ms;\n      transition-delay: ", "ms;\n    "], ["\n      transition-duration: ", "ms;\n      transition-delay: ", "ms;\n    "])), timeout, timeout);
});
SearchFormContainer.defaultProps = {
    position: ['static', 'relative'],
    flexDirection: 'column',
    width: 1,
    my: 0,
    mx: 'auto',
    boxSizing: 'border-box',
    backgroundColor: 'transparent',
};
export default SearchFormContainer;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
