var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var getSuggestionName = function (suggestion) { var _a; return (_a = suggestion === null || suggestion === void 0 ? void 0 : suggestion.name) !== null && _a !== void 0 ? _a : ''; };
export var getInputLength = function (value) { return value.trim().length; };
export var createHighlightedText = function (text, match) {
    // Trim the string and escape special regex chars
    match = match.trim().replace(/[\\^$.*+?()[\]{}|]/g, '\\$&');
    var regex = new RegExp("(".concat(match, ")"), 'ig');
    return match ? text.replace(regex, '<strong>$1</strong>') : text;
};
export var attachEncodedUrl = function (suggestion, urlParams) { return (__assign(__assign({}, suggestion), { urlEncoded: "/search?query=".concat(encodeURIComponent(suggestion.name)).concat(urlParams ? "&".concat(urlParams) : '') })); };
export var mapRecentSearchToSuggestionItem = function (_a) {
    var keyword = _a.keyword, thumbnail = _a.thumbnail;
    return attachEncodedUrl({
        name: keyword,
        image: thumbnail,
        count: 0,
    });
};
