var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from '@emotion/styled';
import { display, space, themeGet } from 'styled-system';
import RecentSearchesList from './RecentSearchesList';
import SuggestionsList from './SuggestionsList';
import SuggestionsListContainer from './SuggestionsListContainer';
import SuggestionsProductList from './SuggestionsProductList';
var VerticalLine = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  border-left: ", " solid ", ";\n"], ["\n  ", ";\n  ", ";\n  border-left: ", " solid ", ";\n"])), display, space, themeGet('borderWidths.small'), themeGet('colors.sys.neutral.border.weak'));
var verticalLineStyles = {
    display: ['none', null, 'block'],
    mx: [null, null, 'sp_24', 'sp_32'],
};
var SuggestionsBox = function (_a) {
    var menuProps = _a.menuProps, hasExtraPadding = _a.hasExtraPadding, isBodyOverlayVisible = _a.isBodyOverlayVisible, productSuggestions = _a.productSuggestions, timeout = _a.timeout, setHighlightedIndex = _a.setHighlightedIndex, inputContainerRef = _a.inputContainerRef, recentSearches = _a.recentSearches, props = __rest(_a, ["menuProps", "hasExtraPadding", "isBodyOverlayVisible", "productSuggestions", "timeout", "setHighlightedIndex", "inputContainerRef", "recentSearches"]);
    var isRecentSearchesOpen = Boolean(recentSearches === null || recentSearches === void 0 ? void 0 : recentSearches.flags.isOpen);
    var isSingleColumn = isRecentSearchesOpen || productSuggestions.length === 0;
    return (React.createElement(SuggestionsListContainer, __assign({}, menuProps, { hasExtraPadding: hasExtraPadding, isSingleColumn: isSingleColumn, timeout: timeout, isBodyOverlayVisible: isBodyOverlayVisible, "data-testid": "suggestion-list-item", inputContainerRef: inputContainerRef }),
        isRecentSearchesOpen ? (React.createElement(RecentSearchesList, { listItems: recentSearches.listItems, recentSearches: recentSearches, getItemProps: props.getItemProps, highlightedIndex: props.highlightedIndex })) : (React.createElement(SuggestionsList, __assign({}, props))),
        !isSingleColumn && (React.createElement(React.Fragment, null,
            React.createElement(VerticalLine, __assign({}, verticalLineStyles)),
            React.createElement(SuggestionsProductList, { productSuggestions: productSuggestions, onMouseEnter: function () { return setHighlightedIndex(-1); } })))));
};
export default SuggestionsBox;
var templateObject_1;
