var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback, useEffect, useState } from 'react';
import { useMatchesViewport } from '../../../hooks/media';
var defaultStyle = { position: 'absolute' };
var calculateLeftMargin = function (_a) {
    var containerWidth = _a.containerWidth, inputWidth = _a.inputWidth, inputX = _a.inputX, matchesDesktop = _a.matchesDesktop;
    if (matchesDesktop) {
        return window.innerWidth / 2 - inputX - containerWidth / 2;
    }
    var widthDiff = (inputWidth - containerWidth) / 2;
    return inputWidth > containerWidth ? -widthDiff : widthDiff;
};
var getLeftMargin = function (_a) {
    var inputContainerRef = _a.inputContainerRef, listRef = _a.listRef, matchesDesktop = _a.matchesDesktop, isSingleColumn = _a.isSingleColumn;
    if (!inputContainerRef.current || !listRef.current || (isSingleColumn && matchesDesktop)) {
        return 0;
    }
    var containerWidth = listRef.current.parentElement.getBoundingClientRect().width;
    var _b = inputContainerRef.current.getBoundingClientRect(), inputX = _b.x, inputWidth = _b.width;
    return calculateLeftMargin({
        containerWidth: containerWidth,
        inputWidth: inputWidth,
        inputX: inputX,
        matchesDesktop: matchesDesktop,
    });
};
var useGetCenterProps = function (_a) {
    var inputContainerRef = _a.inputContainerRef, listRef = _a.listRef, open = _a.open, isSingleColumn = _a.isSingleColumn;
    var _b = useMatchesViewport().matches, matchesDesktop = _b.matchesDesktop, matchesTablet = _b.matchesTablet;
    var _c = __read(useState(0), 2), marginLeft = _c[0], setMarginLeft = _c[1];
    var handleResize = useCallback(function () {
        if (open) {
            setMarginLeft(getLeftMargin({
                inputContainerRef: inputContainerRef,
                listRef: listRef,
                matchesDesktop: matchesDesktop,
                isSingleColumn: isSingleColumn,
            }));
        }
    }, [inputContainerRef, listRef, matchesDesktop, open, isSingleColumn]);
    useEffect(function () {
        handleResize();
    }, [handleResize, open]);
    useEffect(function () {
        window.addEventListener('resize', handleResize);
        return function () { return window.removeEventListener('resize', handleResize); };
    }, [handleResize, matchesDesktop]);
    return matchesTablet && open && __assign(__assign({}, defaultStyle), { ml: "".concat(marginLeft, "px") });
};
export default useGetCenterProps;
