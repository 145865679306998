var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { backgroundColor, borderColor, borderRadius, borderStyle, borderWidth, display, flex, overflow, position, themeGet, zIndex, } from 'styled-system';
import { transitionProperty } from '../../theme/system-utilities';
var SearchInputBorder = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:hover,\n  &:focus {\n    border-color: ", ";\n  }\n\n  ", "\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:hover,\n  &:focus {\n    border-color: ", ";\n  }\n\n  ", "\n"])), display, flex, position, zIndex, overflow, backgroundColor, transitionProperty, borderColor, borderRadius, borderStyle, borderWidth, themeGet('colors.sys.neutral.border.strong'), function (props) {
    var locked = props.locked, timeout = props.timeout;
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      margin-left: ", ";\n      transition-duration: ", "ms;\n      ", ";\n    "], ["\n      margin-left: ", ";\n      transition-duration: ", "ms;\n      ", ";\n    "])), locked
        ? themeGet('space.sp_0')(props)
        : "-".concat(themeGet('space.sp_40')(props), "px"), timeout, locked
        ? css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n            transition-timing-function: ease-out;\n            transition-delay: ", "ms;\n          "], ["\n            transition-timing-function: ease-out;\n            transition-delay: ", "ms;\n          "])), timeout) : css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n            transition-timing-function: ease-in;\n          "], ["\n            transition-timing-function: ease-in;\n          "]))));
});
SearchInputBorder.defaultProps = {
    overflow: 'hidden',
    backgroundColor: 'sys.neutral.background.weak',
    transitionProperty: 'margin-left',
    position: 'relative',
    display: 'flex',
    flex: '1 1 auto',
    borderColor: 'sys.neutral.border.default',
    borderRadius: 'x-large',
    borderStyle: 'solid',
    borderWidth: 1,
};
export default SearchInputBorder;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
