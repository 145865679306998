var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { alignItems, borderColor, borderRadius, borderStyle, borderWidth, display, height, justifyContent, padding, size, width, } from 'styled-system';
import { useLocalization } from '../../providers/LocaleProvider';
import { cursor, fill } from '../../theme/system-utilities';
import { Flex } from '../Flex';
import Icon from '../Icon';
var CONTAINER_SIZE = 42;
var SEARCH_ICON_SIZE = 24;
var MINIMAL_SEARCH_ICON_SIZE = 13;
var StyledIcon = styled(Icon)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), fill, size);
var iconStyles = {
    fill: 'sys.neutral.icon.default',
    role: 'presentation',
    size: SEARCH_ICON_SIZE,
};
var Container = styled(Flex)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), cursor, size);
var containerStyles = {
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'center',
    size: CONTAINER_SIZE,
};
var AnimatedContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  ", "\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  ", "\n"])), padding, borderRadius, borderWidth, borderColor, borderStyle, display, width, cursor, alignItems, justifyContent, width, height, function () { return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    @keyframes shrinkSearchWidth {\n      from {\n        width: 100%;\n        margin-top: 16px;\n        padding-right: 12px;\n      }\n      to {\n        width: 24px;\n        margin-top: 0px;\n        justify-content: center;\n      }\n    }\n\n    animation: shrinkSearchWidth 0.5s ease-in-out forwards;\n    float: right;\n  "], ["\n    @keyframes shrinkSearchWidth {\n      from {\n        width: 100%;\n        margin-top: 16px;\n        padding-right: 12px;\n      }\n      to {\n        width: 24px;\n        margin-top: 0px;\n        justify-content: center;\n      }\n    }\n\n    animation: shrinkSearchWidth 0.5s ease-in-out forwards;\n    float: right;\n  "]))); });
var animatedContainerStyles = {
    padding: 'sp_8',
    borderRadius: '100px',
    borderWidth: 'small',
    borderColor: 'sys.neutral.border.default',
    borderStyle: 'solid',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    cursor: 'pointer',
    height: '24px',
};
var SearchIcon = function (_a) {
    var onClick = _a.onClick, _b = _a.withAnimation, withAnimation = _b === void 0 ? false : _b, _c = _a.minimalIcon, minimalIcon = _c === void 0 ? false : _c;
    var t = useLocalization().t;
    var IconContainer = withAnimation ? AnimatedContainer : Container;
    var iconContainerStyles = withAnimation ? animatedContainerStyles : containerStyles;
    var iconSize = minimalIcon ? MINIMAL_SEARCH_ICON_SIZE : SEARCH_ICON_SIZE;
    var iconName = minimalIcon ? 'searchFillSmall' : 'search';
    return (React.createElement(IconContainer, __assign({ onClick: onClick, "data-testid": "search-icon-container", "aria-label": t('h24_search_button_text'), role: "button" }, iconContainerStyles),
        React.createElement(StyledIcon, __assign({ name: iconName }, iconStyles, { size: iconSize }))));
};
export default SearchIcon;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
