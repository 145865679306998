var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useMemo, useRef, useState } from 'react';
import { useDisableBodyScroll } from '../../../../hooks/scroll';
import { useLocalization } from '../../../../providers/LocaleProvider';
import useSearchLock from '../../../AutocompleteInput/hooks/use-search-lock';
import { getInputLength } from '../../utils/search';
import { useHandlers } from './handlers';
import { useQuery } from './query';
var SEARCH_ACTION_PATH = '/search';
var usePlaceholder = function (focused, shopName) {
    var t = useLocalization().t;
    if (focused) {
        return '';
    }
    return shopName
        ? "".concat(shopName, " ").concat(t('h24_search_bar_shop_placeholder'))
        : t('h24_search_bar_placeholder');
};
var useActionPath = function () {
    var prefix = useLocalization().prefix;
    return prefix(SEARCH_ACTION_PATH);
};
export var useInput = function (_a) {
    var isMobile = _a.isMobile, shopName = _a.shopName, withInputFilled = _a.withInputFilled;
    var _b = __read(useState(''), 2), value = _b[0], setValue = _b[1];
    var _c = __read(useState(false), 2), isEditedByUser = _c[0], setIsEditedByUser = _c[1];
    var _d = __read(useState(false), 2), focused = _d[0], setFocused = _d[1];
    var _e = __read(useState(false), 2), isSearchClearFocused = _e[0], setSearchClearFocused = _e[1];
    var _f = useSearchLock(), isLocked = _f.isLocked, lockSearch = _f.lockSearch, unlockSearch = _f.unlockSearch, searchContainerRef = _f.searchContainerRef, searchPlaceholderRef = _f.searchPlaceholderRef;
    var isClearButtonHidden = useMemo(function () { return getInputLength(value) === 0; }, [value]);
    var ref = useRef(null);
    var fakeRef = useRef(null);
    var placeholder = usePlaceholder(focused, shopName);
    var handlers = useHandlers({
        ref: ref,
        placeholder: placeholder,
        setValue: setValue,
        setIsEditedByUser: setIsEditedByUser,
        setFocused: setFocused,
        setSearchClearFocused: setSearchClearFocused,
        unlockSearch: unlockSearch,
    });
    useQuery(setValue, withInputFilled);
    useDisableBodyScroll(focused && isMobile, '[data-testid="suggestions-list"]');
    return {
        value: value,
        setValue: setValue,
        refs: {
            input: ref,
            fakeInput: fakeRef,
            container: searchContainerRef,
            placeholder: searchPlaceholderRef,
        },
        actionPath: useActionPath(),
        placeholder: placeholder,
        flags: {
            isEditedByUser: isEditedByUser,
            focused: focused,
            isSearchClearFocused: isSearchClearFocused,
            isClearButtonHidden: isClearButtonHidden,
            isLocked: isLocked,
        },
        handlers: __assign(__assign({}, handlers), { lockSearch: lockSearch, unlockSearch: unlockSearch }),
    };
};
